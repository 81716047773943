import './index.css'
import React, {Component} from 'react'
import {Badge, Button, DatePicker, Icon, List, Modal, NavBar, Tag, Toast} from 'antd-mobile';
import {Axios, BASE_URL} from '../../../utils/url'
import {getDateTimeStr} from '../../../utils/dateUtils'
import ListItem from "antd-mobile/es/list/ListItem";

const now = new Date();
let currentDate = now;
let nowDateStr;
let currentDateStr = '';
let currentTimeStr = ''
let yearNow = now.getFullYear();
let monthNow = now.getMonth() + 1;
let dayNow = now.getDate();
if (monthNow < 10) {
  monthNow = '0' + monthNow;
}
if (dayNow < 10) {
  dayNow = '0' + dayNow;
}
nowDateStr = yearNow + '-' + monthNow + '-' + dayNow;

const formatDateTimeStrFun = (date) => {

  let yearCurrent = date.getFullYear();
  let monthCurrent = date.getMonth() + 1;
  let dayCurrent = date.getDate();
  if (monthCurrent < 10) {
    monthCurrent = '0' + monthCurrent;
  }
  if (dayCurrent < 10) {
    dayCurrent = '0' + dayCurrent;
  }
  currentDateStr = yearCurrent + '-' + monthCurrent + '-' + dayCurrent;

  let hourCurrent = date.getHours();
  let minuteCurrent = date.getMinutes();
  let secondsCurrent = '00';

  if (hourCurrent < 10) {
    hourCurrent = '0' + hourCurrent;
  }
  if (minuteCurrent < 10) {
    minuteCurrent = '0' + minuteCurrent;
  }

  currentTimeStr = hourCurrent + ':' + minuteCurrent + ':' + secondsCurrent;

}

formatDateTimeStrFun(now)

const formatDateStrFun = (date) => {
  if (!date) {
    date = now
  }
  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  const md = date.getFullYear() + '年' + month + '月' + day + '日';

  const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const weekIdx = date.getDay();
  let week = weeks[weekIdx];
  return md + ' ' + week;
}

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const alert = Modal.alert;

export default class HistoryTicketOrder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      date: now,
      dateStrShow: formatDateStrFun(now),
      titleList: [{xl: '线路/乘客', status: '状态', op: '操作'}],
      yyList: [],
      cancelLoading: false,
    }
  }

  componentDidMount = () => {
    formatDateTimeStrFun(now)
    currentDate = now
    this.getSqrCpyyList()
  }

  getSqrCpyyList = () => {
    let params = {
      sqrsfzh: userInfo.sfzh
    }
    Axios({
      method: 'get',
      url: BASE_URL + "cpyy/getBySqrsfzhAndYysj",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        this.setState({yyList: resData.data});
      } else {
        Toast.fail("获取预约列表失败!");
      }
    });
  }

  onConfirm = (date) => {
    currentDate = date;
    this.setState({date: date, visible: false})
    this.formatDateStr(date)
  }

  formatDateStr = (date) => {
    const dateStrShow = formatDateStrFun(date);
    formatDateTimeStrFun(date)
    this.setState({dateStrShow: dateStrShow});

    this.getSqrCpyyList()
  }

  isCanCancelOrder = (item) => {
    if (!item.YYSJ || item.STATUS !== '0') {
      return true;
    }
    let yysj = item.YYSJ
    const currentYysj = yysj.substring(0, 10) + " " + "19:30:00";
    const nowDateTimeStr = nowDateStr + " " + currentTimeStr;

    const yysjDate = new Date(currentYysj)
    yysjDate.setDate(yysjDate.getDate() - 1); // 减去一天
    const newYysj = getDateTimeStr(yysjDate);

    return newYysj <= nowDateTimeStr;
  }

  cancelYy = (item) => {
    this.setState({cancelLoading: true})
    alert('取消当前已预约线路', '确定吗？', [
      {text: '取消', onPress: () => this.setState({cancelLoading: false})},
      {
        text: '确定', onPress: () => {
          Axios({
            method: 'post',
            url: BASE_URL + "cpyy/deleteByYyId",
            params: {
              yyId: item.YYID,
            },
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          }).then((res) => {
            this.setState({cancelLoading: false})
            const resData = res.data;
            if (resData) {
              if (resData.flag) {
                this.getSqrCpyyList();
                Toast.info("取消预约成功!");
              } else {
                Toast.fail(resData.msg);
              }
            } else {
              Toast.fail("取消预约错误！");
            }
          });
        }
      },
    ])
  }

  myTitleList = () => (
    <List>
      {this.state.titleList.map(item => (
        <ListItem key={item.xl}>
          <div className="item-wrapper">
            <div className="col1">
              {item.xl}
            </div>
            <div className="col2">
              {item.status}
            </div>
            <div className="col3">
              {item.op}
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )

  myList = () => (
    <List>
      {this.state.yyList.map(item => (
        <ListItem key={item.YYSJ + item.XLID + item.SFZH}>
          <div className="item-wrapper">
            <div className="yy-col1">
              <div style={{textAlign: "center", fontSize: "14px", marginTop: '5px'}}>
                <Badge text={item.FX} style={{
                  marginLeft: 12,
                  backgroundColor: item.FX === '回' ? '#21B68A' : '#ff5b05'
                }}>{item.CFD} - {item.MDD}</Badge>
              </div>
              <div
                style={{textAlign: "center", fontSize: "12px", marginTop: '5px', color: "red"}}>
                预约时间：{item.YYSJ.substring(0, 10)}
              </div>
              <div
                style={{textAlign: "center", fontSize: "12px", color: "red"}}>
                乘坐地点：{item.CZDD}
              </div>
              <div style={{textAlign: "center", fontSize: "12px", marginTop: '5px'}}>
                {item.YYRXM}
              </div>
              <div style={{textAlign: "center", fontSize: "12px", marginBottom: '5px'}}>
                {item.SFZH}
              </div>
            </div>
            <div className="yy-col2">
              <Tag selected={item.STATUS !== '2'}>
                {item.STATUS === '0' ? '已预约' : item.STATUS === '1' ? '已乘坐' : item.STATUS === '2' ? '未乘坐' : ''}
              </Tag>
            </div>
            <div className="yy-col3">
              <Button style={{display: item.STATUS === '0' ? 'block' : 'none'}} type="warning" size="small"
                      onClick={() => this.cancelYy(item, 'cancel')}
                      disabled={this.state.cancelLoading || this.isCanCancelOrder(item)}>取消</Button>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )

  render() {
    return (
      <div className="container">
        <NavBar
          mode="dark"
          icon={<Icon type="left"/>}
          onLeftClick={() => this.props.history.goBack()}
        >预约历史</NavBar>
        <div className="his-ordered-wrapper">
          <div className="table-wrapper">
            <div className="table-title">
              <div className="cancel-tips">注意：发车前一天的19:30之前，可取消预约！</div>
              <div className="cancel-tips">&emsp;&emsp;&emsp;预约历史只展示近三个月信息。</div>
              {this.myTitleList()}
            </div>
            <div className="table-content">
              {this.myList()}
            </div>
          </div>

          <DatePicker
            visible={this.state.visible}
            mode="date"
            title="日期选择"
            value={this.state.date}
            onOk={date => this.onConfirm(date)}
            onDismiss={() => this.setState({visible: false})}
          />
        </div>
      </div>
    )
  }
}
