import './index.css'
import React, {Component} from 'react'
import {Axios, BASE_URL, NEW_YGK_URL} from '../../../utils/url'
import {Button, DatePicker, Icon, InputItem, List, Modal, NavBar, Picker, Switch, Toast} from "antd-mobile";
import ListItem from "antd-mobile/es/list/ListItem";
import CheckboxItem from "antd-mobile/es/checkbox/CheckboxItem";
import closest from "antd-mobile/es/_util/closest";
import {getDateTimeStr, getEndTimeDate, getStartTimeDate} from "../../../utils/dateUtils";

const alert = Modal.alert;
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const openId = localStorage.getItem("openid");

const now = new Date();
const minDate = getStartTimeDate(new Date(now.getTime() + 24 * 60 * 60 * 1000)); // 最小选择日期
const maxDate = getEndTimeDate(new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000)); // 最大选择日期

export default class AddPerson extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(this.props.location.search);
    this.state = {
      personList: [],
      orderedPersonList: [],
      addPersonVisible: false,
      addBcVisible: false,
      bclxVisible: false,
      bclxValue: '',
      bclxShowList: [],
      bcVisible: false,
      bczwShow: '',
      bcValue: '',
      bcidValue: '',
      bcShowList: [],
      isXlxVisible: false,
      isXlxValue: '',
      isXlxShowList: [[{label: '是', value: '是'}, {label: '否', value: '否'}]],
      currentYyrIdx: -1,
      cfDateTime: '',
      cfDateTimeStr: '',
      ddrqVisible: false,
      ddDateTime: '',
      ddDateTimeStr: '',
      cfrqVisible: false,
      hasError: false,
      hasSfzError: false,
      hasCcrNameError: false,
      value: '',
      ccrid: '',
      idCard: '',
      ccrName: '',
      yktId: '',
      phoneNum: '',
      submitLoading: false,
      submitYyrLoading: false,
      isEdit: false,
      isShowDel: false,
      xlId: queryParams.get("xlId"),
      yysj: queryParams.get("yysj"),
      checkedYyrList: []
    }
  }

  componentDidMount = () => {
    this.getPersonList();
    this.getOrderedPerson()
    this.getBclxList()
  }

  getPersonList = () => {
    let params = {
      idCard: userInfo.sfzh
    }
    Axios({
      method: 'get',
      url: BASE_URL + "ccrxx/getListByIdCard",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        this.setState({personList: resData.data});
      } else {
        Toast.fail("获取乘客列表失败!");
      }
    });
  }

  getOrderedPerson = () => {
    let params = {
      xlId: this.state.xlId,
      yysj: this.state.yysj,
      status: '0'
    }

    Axios({
      method: 'get',
      url: BASE_URL + "cpyy/getOrderedPersonByXlId",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        this.setState({orderedPersonList: resData.data});
      } else {
        Toast.fail("获取已预约列表失败!");
      }
    });
  }

  getBclxList = () => {
    Axios({
      method: 'get',
      url: BASE_URL + "bc/getBclxList",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        if (resData.data && resData.data.length > 0) {
          let bclxList = [];
          for (const resDatum of resData.data) {
            bclxList.push({label: resDatum, value: resDatum});
          }
          this.state.bclxShowList.push(bclxList);
        }
      } else {
        Toast.fail("获取班次类型失败!");
      }
    });
  }

  getListByBclx = (bclx) => {
    if (!bclx) {
      bclx = ''
    }
    this.setState({
      bcShowList: [],
      bcValue: '',
      bcidValue: '',
    });
    let params = {
      bclx: bclx,
      yysj: this.state.yysj,
    }
    Axios({
      method: 'get',
      url: BASE_URL + "bc/getListByBclx",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        if (resData.data && resData.data.length > 0) {
          let bcList = [];
          for (const resDatum of resData.data) {
            bcList.push({
              label: resDatum.bch + ' ' + resDatum.hblj,
              value: resDatum.bch + ' ' + resDatum.hblj,
              bcid: resDatum.bcid,
              bczw: resDatum.bczw
            });
          }
          this.state.bcShowList.push(bcList);
        }
      } else {
        Toast.fail("获取班次类型失败!");
      }
    });
  }


  submitPerson = () => {
    // 判断是否在乘客列表
    const isExist = this.state.personList.some((item) => {
      return item.idCard === this.state.idCard
    });

    if (isExist && !this.state.isEdit) {
      Toast.info('该身份证号已存在！');
      return
    }

    if (!this.state.idCard || this.state.hasSfzError) {
      Toast.info('请输入正确的身份证号！');
      return
    }

    if (!this.state.ccrName) {
      Toast.info('乘客姓名不能为空！');
      return
    }

    /*if (!this.state.yktId) {
      Toast.info('一卡通号不能为空！');
      return
    }*/

    if (!this.state.phoneNum || this.state.hasError) {
      Toast.info('请输入正确的手机号！');
      return
    }

    let idCardArr = []
    this.state.personList.forEach(item => {
      idCardArr.push(item.idCard)
    })

    let idCardString = idCardArr.join(',');
    if (idCardString) {
      if (!idCardString.includes(this.state.idCard)) {
        idCardString += ',' + this.state.idCard
      }
    } else {
      idCardString = this.state.idCard;
    }

    let params = {
      ccrid: this.state.ccrid,
      idCard: this.state.idCard,
      ccrName: this.state.ccrName,
      yktId: this.state.yktId,
      phoneNum: this.state.phoneNum,
      appendIdCards: idCardString,
      openId: openId
    }
    this.setState({submitLoading: true})
    Axios({
      method: 'post',
      url: BASE_URL + "ccrxx/save",
      data: params,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      this.state.addPersonVisible = false;
      this.setState({submitLoading: false})
      const resData = res.data;
      if (resData && resData.flag) {
        Toast.info("添加成功!");
        this.getPersonList();
      } else {
        Toast.fail("添加乘客失败!");
      }
      this.onClearFormData()
    });
  }

  delPerson = () => {
    this.setState({submitLoading: true})
    alert('删除乘客', '确定吗？', [
      {text: '取消', onPress: () => this.setState({submitLoading: false})},
      {
        text: '确定', onPress: () => {
          Axios({
            method: 'post',
            url: BASE_URL + "ccrxx/deleteByIdCard",
            params: {
              idCard: this.state.idCard,
              currentIdCard: userInfo.sfzh,
            },
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          }).then((res) => {
            this.state.addPersonVisible = false;
            this.setState({submitLoading: false})
            const resData = res.data;
            if (resData && resData.flag) {
              Toast.info("删除成功!");
              this.getPersonList();
            } else {
              Toast.fail("删除乘客失败!");
            }
            this.onClearFormData()
          });
        }
      },
    ])
  }

  onErrorClick = () => {
    if (this.state.hasError) {
      Toast.info('请输入11位数字', 1, null, false);
    }
  }

  onErrorCcrNameClick = () => {
    if (this.state.hasCcrNameError) {
      Toast.info('请输入姓名', 1, null, false);
    }
  }

  onErrorSfzClick = () => {
    if (this.state.hasSfzError) {
      Toast.info('请输入18位身份证号', 1, null, false);
    }
  }

  showModal = key => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    });
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
    this.onClearFormData()
  }

  onClearFormData = () => {
    this.setState({
      ccrid: '',
      idCard: '',
      ccrName: '',
      yktId: '',
      phoneNum: '',
      isEdit: false,
      isShowDel: false,
      bclxValue: '',
      bcValue: '',
      bcidValue: '',
      bcShowList: [],
      cfDateTime: '',
      cfDateTimeStr: '',
      ddDateTime: '',
      ddDateTimeStr: '',
    })
  }

  onChange = (e, item) => {
    let yyr = {
      yyrxm: '',
      sfzh: '',
      ykt: '',
      sjh: '',
      xlid: this.state.xlId
    }
    if (e.target.checked) {
      yyr.yyrxm = item.ccrName
      yyr.sfzh = item.idCard
      yyr.ykt = item.yktId
      yyr.sjh = item.phoneNum
      yyr.haveXlx = '否'

      this.state.checkedYyrList.push(yyr)
    } else {
      if (this.state.checkedYyrList.length > 0) {
        let yyrIdx = -1
        this.state.checkedYyrList.forEach((i, idx) => {
          if (i.sfzh === item.idCard) {
            yyrIdx = idx
          }
        })

        if (yyrIdx !== -1) {
          this.state.checkedYyrList.splice(yyrIdx, 1)
        }
      }
    }
  }

  onWrapTouchStart = (e) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, '.am-modal-content');
    if (!pNode) {
      e.preventDefault();
    }
  }

  onShowEditBtn = (item) => {
    if (this.state.orderedPersonList.length > 0) {
      let orderedSfzhStr = ''
      this.state.orderedPersonList.forEach(i => {
        orderedSfzhStr += i.sfzh
      })
      return !orderedSfzhStr.includes(item.idCard)
    } else {
      return true
    }
  }

  myList = () => (
    <List renderHeader={() => '请勾选预约乘客'}>
      {this.state.personList.map(item => (
        <ListItem key={item.idCard}>
          <div className="person-item-wrapper">
            <div className="check-wrapper">
              <CheckboxItem disabled={!this.onShowEditBtn(item)} key={item.idCard}
                            onChange={(e) => this.onChange(e, item)}>
                <div className="list-wrapper">
                  <div className="person-info">
                    <div style={{fontSize: '16px'}}>{item.ccrName}</div>
                    <div className="mt5" style={{color: '#666', fontSize: '12px'}}>{item.idCard}</div>
                    <div className="mt5" style={{color: '#666', fontSize: '12px'}}>{item.phoneNum}</div>
                    <div className="mt5" style={{color: '#666', fontSize: '12px'}}>{item.yktId}</div>
                  </div>
                </div>
              </CheckboxItem>
            </div>
            <div className="op-edit">
              <Button size="small" type="primary" style={{display: this.onShowEditBtn(item) ? 'block' : 'none'}}
                      onClick={() => this.onEdit(item)}>编&emsp;辑</Button>
              <Button size="small" type="primary" disabled={true}
                      style={{display: this.onShowEditBtn(item) ? 'none' : 'block'}}>已预约</Button>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  );

  onEdit = (item) => {
    const {ccrid, idCard, ccrName, yktId, phoneNum} = item;
    this.setState({
      ccrid: ccrid,
      idCard: idCard,
      ccrName: ccrName,
      yktId: yktId,
      phoneNum: phoneNum,
    })
    this.state.addPersonVisible = true;
    this.state.isEdit = true;
    this.state.isShowDel = true;
  }

  onChangeIdCard = (value) => {
    const str = value.replace(/\s*/g, "");
    if (str.replace(/\s/g, '').length !== 18) {
      this.setState({
        hasSfzError: true,
      });
    } else {
      this.setState({
        hasSfzError: false,
      });
    }
    this.setState({
      idCard: str,
    });
  }

  onCcrNameChange = (value) => {
    const str = value.replace(/\s*/g, "");
    if (str.length < 1) {
      this.setState({
        hasCcrNameError: true,
      });
    } else {
      this.setState({
        hasCcrNameError: false,
      });
    }
    this.setState({
      ccrName: str,
    });
  }

  onPhChange = (value) => {
    const str = value.replace(/\s*/g, "");
    if (str.length < 11) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
    }
    this.setState({
      phoneNum: str,
    });
  }

  async getYgkxx(sfzh) {
    const url = NEW_YGK_URL + 'bryxxController/getYgkxx';
    let params = new URLSearchParams();
    params.append("sfzh", sfzh);
    this.setState({yktId: ''}) //先清空
    await Axios({
      method: 'post',
      url: url,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      if (res.data.flag && res.data.ygkxx.length > 0) {
        this.setState({
          yktId: res.data.ygkxx[0].grzh,
        });
      } else {
        this.setState({
          yktId: '',
        });
      }
    });
  }

  async getRyzcxx(sfzh) {
    const url = BASE_URL + 'bRyzcController/getRyzcInfo';
    this.setState({
      phoneNum: '',
      ccrName: ''
    });
    let params = new URLSearchParams();
    params.append("sfzh", sfzh);
    await Axios({
      method: 'post',
      url: url,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      if (res.data && res.data.flag) {
        this.setState({
          phoneNum: res.data.data.sjh,
          ccrName: res.data.data.xm,
        });
      } else {
        this.setState({
          phoneNum: '',
          ccrName: '',
        });
      }
    });
  }

  onBlurIdCard = (value) => {
    if (value) {
      this.getYgkxx(value);
      this.getRyzcxx(value)
    } else {
      this.setState({phoneNum: ''})
      this.setState({yktId: ''})
      this.setState({ccrName: ''})
    }
  }

  confirmYyr = () => {
    if (this.state.checkedYyrList.length === 0) {
      Toast.fail("请至少勾选一位乘客!");
      return;
    }
    // 添加班次信息
    this.setState({addBcVisible: true});
  }

  submitYyrInfo = () => {
    this.setState({submitYyrLoading: true});
    if (!this.state.bclxValue) {
      Toast.info('请选择班次类型！');
      return
    }
    if (!this.state.bcValue) {
      Toast.info('请选择班次！');
      return
    }
    if (!this.state.cfDateTime) {
      Toast.info('请选择出发日期！');
      return
    }
    if (!this.state.ddDateTime) {
      Toast.info('请选择到达日期！');
      return
    }

    if (this.state.bczwShow === 0 || (this.state.bczwShow && this.state.bczwShow < this.state.checkedYyrList.length)) {
      Toast.info('余座数量小于预约人数，不可预约！');
      return
    }

    let params = {
      yyrList: this.state.checkedYyrList,
      openId: openId,
      yysj: this.state.yysj,
      cfDateTimeStr: getDateTimeStr(this.state.cfDateTime),
      ddDateTimeStr: getDateTimeStr(this.state.ddDateTime),
      bcValue: this.state.bcValue,
      bcidValue: this.state.bcidValue,
      bclxValue: this.state.bclxValue,
    }
    Axios({
      method: 'post',
      url: BASE_URL + "cpyy/save",
      data: params,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res) => {
      this.setState({submitYyrLoading: false});
      const resData = res.data;
      if (resData) {
        if (resData.flag) {
          Toast.success(resData.msg);
          this.props.history.goBack()
        } else {
          Toast.fail(resData.msg)
        }
      } else {
        Toast.fail("预约错误！")
      }
    });
  }

  onBclxConfirm = (value) => {
    this.setState({
      bclxValue: value[0],
      bclxVisible: false,
    });
    this.getListByBclx(value[0])
  }

  onBcConfirm = (value) => {
    // 获取座位数
    const bcFilter = this.state.bcShowList[0].filter(item => {
      return item.value === value[0]
    })

    this.setState({
      bcValue: value[0],
      bcVisible: false,
      bczwShow: bcFilter[0].bczw,
      bcidValue: bcFilter[0].bcid
    });
  }

  onCfConfirm = (value) => {
    this.setState({
      cfDateTimeStr: getDateTimeStr(value),
      cfDateTime: value,
      cfrqVisible: false,
    });
  }

  onDdConfirm = (value) => {
    this.setState({
      ddDateTimeStr: getDateTimeStr(value),
      ddDateTime: value,
      ddrqVisible: false,
    });
  }

  onXlxConfirm = (value) => {
    // 给当前预约人赋值
    if (this.state.currentYyrIdx !== -1) {
      this.state.checkedYyrList[this.state.currentYyrIdx].haveXlx = value[0];
    }
    this.setState({
      isXlxVisible: false,
      currentYyrIdx: -1,
    });
  }

  isHaveLuggageList = () => (
    <List renderHeader={() => <div>乘客是否有行李箱</div>}>
      {this.state.checkedYyrList.map((item, index) => (
        <ListItem key={item.sfzh}>
          <InputItem placeholder="是否有行李箱"
                     labelNumber={4}
                     style={{textAlign: 'left'}}
                     editable={false}
                     clear={false}
                     onClick={() => {
                       this.setState({
                         isXlxVisible: true,
                         currentYyrIdx: index,
                       });
                     }}
                     value={item.haveXlx}
                     type="text"
          >{item.yyrxm}</InputItem>
        </ListItem>
      ))}
    </List>
  );

  render() {
    return (
      <div className="container">
        <NavBar
          mode="dark"
          icon={<Icon type="left"/>}
          onLeftClick={() => this.props.history.goBack()}
        >选择乘客</NavBar>

        <div className="person-wrapper">
          <div className="add-tool">
            <Button type="ghost" onClick={this.showModal('addPersonVisible')}>添加乘客</Button>
          </div>
          <div className="person-list">
            {this.myList()}
          </div>
          <div className="confirm-tool">
            <Button type="primary" onClick={() => this.confirmYyr()}>确认</Button>
          </div>
        </div>

        <Modal
          visible={this.state.addPersonVisible}
          transparent
          maskClosable={false}
          style={{width: '90%', height: '310px'}}
          onClose={this.onClose('addPersonVisible')}
          wrapProps={{onTouchStart: this.onWrapTouchStart}}
        >
          <div className="modal-wrapper">
            <div className="modal-content">
              <List renderHeader={() => <div style={{fontSize: '16px'}}>乘客信息</div>}>
                <InputItem placeholder="请输入身份证号"
                           labelNumber={18}
                           style={{textAlign: 'left'}}
                           editable={true}
                           clear={true}
                           type="text"
                           onChange={this.onChangeIdCard}
                           onBlur={this.onBlurIdCard}
                           error={this.state.hasSfzError}
                           onErrorClick={this.onErrorSfzClick}
                           value={this.state.idCard}
                >身份证号</InputItem>
                <InputItem placeholder="请输入姓名"
                           labelNumber={32}
                           style={{textAlign: 'left'}}
                           editable={true}
                           clear={true}
                           type="text"
                           value={this.state.ccrName}
                           error={this.state.hasCcrNameError}
                           onChange={this.onCcrNameChange}
                           onErrorClick={this.onErrorCcrNameClick}
                >乘客姓名</InputItem>
                <InputItem placeholder="一卡通号"
                           labelNumber={16}
                           style={{textAlign: 'left'}}
                           editable={false}
                           clear={false}
                           type="text"
                           value={this.state.yktId}
                >一卡通号</InputItem>
                <InputItem
                  style={{textAlign: 'left'}}
                  editable={true}
                  clear={true}
                  labelNumber={11}
                  type="phone"
                  placeholder="请输入手机号码"
                  error={this.state.hasError}
                  onErrorClick={this.onErrorClick}
                  onChange={this.onPhChange}
                  value={this.state.phoneNum}
                >手机号码</InputItem>
              </List>
            </div>
            <div className="modal-footer">
              <Button size="small" disabled={this.state.submitLoading}
                      className="mr5" type="ghost" onClick={this.onClose('addPersonVisible')}>取消</Button>
              <Button style={{display: this.state.isShowDel ? 'block' : 'none'}} size="small"
                      disabled={this.state.submitLoading}
                      className="mr5 ml5" type="warning" onClick={() => this.delPerson()}>删除</Button>
              <Button size="small" disabled={this.state.submitLoading}
                      className="ml5" type="primary" onClick={this.submitPerson}>提交</Button>
            </div>
          </div>
        </Modal>
        <Modal
          title='确认乘客火车或飞机班次信息'
          visible={this.state.addBcVisible}
          transparent
          maskClosable={false}
          style={{width: '95%', height: '550px'}}
          onClose={this.onClose('addBcVisible')}
          wrapProps={{onTouchStart: this.onWrapTouchStart}}
        >
          <div className="bc-modal-wrapper">
            <div className="bc-modal-content">
              <InputItem placeholder="请选择类型"
                         labelNumber={32}
                         style={{textAlign: 'left'}}
                         editable={false}
                         clear={false}
                         onClick={() => {
                           this.setState({
                             bclxVisible: true,
                           });
                         }}
                         value={this.state.bclxValue}
                         type="text"
              >类型</InputItem>
              <InputItem placeholder="请选择班次"
                         labelNumber={32}
                         style={{textAlign: 'left'}}
                         editable={false}
                         clear={false}
                         onClick={() => {
                           if (this.state.bcShowList.length === 0) {
                             Toast.info("请先选择班次类型！")
                             return;
                           }
                           this.setState({
                             bcVisible: true,
                           });
                         }}
                         value={this.state.bcValue}
                         type="text"
                         extra={
                           <div style={{display: this.state.bczwShow || this.state.bczwShow === 0 ? 'block' : 'none'}}>
                             余座:<span style={{color: '#21B68A'}}>{this.state.bczwShow}</span>
                           </div>
                         }
              >班次</InputItem>
              <InputItem placeholder="请选择出发日期"
                         labelNumber={32}
                         style={{textAlign: 'left'}}
                         editable={false}
                         clear={false}
                         onClick={() => {
                           this.setState({
                             cfrqVisible: true,
                           });
                         }}
                         value={this.state.cfDateTimeStr}
                         type="text"
              >出发日期</InputItem>
              <InputItem placeholder="请选择到达日期"
                         labelNumber={32}
                         style={{textAlign: 'left'}}
                         editable={false}
                         clear={false}
                         onClick={() => {
                           this.setState({
                             ddrqVisible: true,
                           });
                         }}
                         value={this.state.ddDateTimeStr}
                         type="text"
              >到达日期</InputItem>
              <div className="luggage-wrapper">
                {this.isHaveLuggageList()}
              </div>
            </div>
            <div className="bc-modal-footer">
              <Button size="small"
                      className="mr5" type="ghost" disabled={this.state.submitYyrLoading}
                      onClick={this.onClose('addBcVisible')}>取消</Button>
              <Button size="small"
                      className="ml5" type="primary" disabled={this.state.submitYyrLoading}
                      onClick={this.submitYyrInfo}>提交</Button>
            </div>
          </div>
        </Modal>
        <Picker
          visible={this.state.bclxVisible}
          data={this.state.bclxShowList}
          title="选择类型"
          cols={1}
          cascade={false}
          onOk={v => this.onBclxConfirm(v)}
          onDismiss={() => this.setState({bclxVisible: false})}
        />
        <Picker
          visible={this.state.bcVisible}
          data={this.state.bcShowList}
          title="选择班次"
          cols={1}
          cascade={false}
          onOk={v => this.onBcConfirm(v)}
          onDismiss={() => this.setState({bcVisible: false})}
        />
        <DatePicker
          visible={this.state.cfrqVisible}
          mode="datetime"
          title="日期选择"
          minDate={minDate}
          maxDate={this.state.ddDateTime && this.state.ddDateTime < maxDate ? this.state.ddDateTime : maxDate}
          value={this.state.cfDateTime}
          onOk={date => this.onCfConfirm(date)}
          onDismiss={() => this.setState({cfrqVisible: false})}
        />
        <DatePicker
          visible={this.state.ddrqVisible}
          mode="datetime"
          title="日期选择"
          minDate={this.state.cfDateTime ? this.state.cfDateTime : minDate}
          maxDate={maxDate}
          value={this.state.ddDateTime}
          onOk={date => this.onDdConfirm(date)}
          onDismiss={() => this.setState({ddrqVisible: false})}
        />
        <Picker
          visible={this.state.isXlxVisible}
          data={this.state.isXlxShowList}
          title="有行李箱"
          cols={1}
          cascade={false}
          onOk={v => this.onXlxConfirm(v)}
          onDismiss={() => this.setState({isXlxVisible: false})}
        />
      </div>
    )
  }
}
