export const getDateTimeStr = (date) => {
  if (!date) {
    return '';
  }
  let yearDate = date.getFullYear();
  let monthDate = date.getMonth() + 1;
  let dayDate = date.getDate();
  if (monthDate < 10) {
    monthDate = '0' + monthDate;
  }
  if (dayDate < 10) {
    dayDate = '0' + dayDate;
  }
  const dateStr = yearDate + '-' + monthDate + '-' + dayDate;

  let hourDate = date.getHours();
  let minuteDate = date.getMinutes();
  let secondsDate = '00';

  if (hourDate < 10) {
    hourDate = '0' + hourDate;
  }
  if (minuteDate < 10) {
    minuteDate = '0' + minuteDate;
  }

  const timeStr = hourDate + ':' + minuteDate + ':' + secondsDate;

  return dateStr + ' ' + timeStr;
}


export const getDateStr = (date) => {
  if (!date) {
    return '';
  }
  let yearDate = date.getFullYear();
  let monthDate = date.getMonth() + 1;
  let dayDate = date.getDate();
  if (monthDate < 10) {
    monthDate = '0' + monthDate;
  }
  if (dayDate < 10) {
    dayDate = '0' + dayDate;
  }
  return yearDate + '-' + monthDate + '-' + dayDate;
}

export const getTimeStr = (date) => {
  if (!date) {
    return '';
  }

  let hourDate = date.getHours();
  let minuteDate = date.getMinutes();
  let secondsDate = date.getSeconds();

  if (hourDate < 10) {
    hourDate = '0' + hourDate;
  }
  if (minuteDate < 10) {
    minuteDate = '0' + minuteDate;
  }

  return hourDate + ':' + minuteDate + ':' + secondsDate;
}

export const getStartTimeDate = (date) => {
  return  new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const getEndTimeDate = (date) => {
  return  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
}
