import './index.css'
import React, {Component} from 'react'
import {Button, DatePicker, Icon, List, Modal, NavBar, Picker, Tag, Toast} from 'antd-mobile';
import {Axios, BASE_URL} from '../../../utils/url'
import ListItem from "antd-mobile/es/list/ListItem";
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';
import * as XLSXStyle from "xlsx-style";

const now = new Date();
let currentDate = now;
let nowDateStr;
let currentDateStr = '';
let currentTimeStr = ''
let yearNow = now.getFullYear();
let monthNow = now.getMonth() + 1;
let dayNow = now.getDate();
if (monthNow < 10) {
  monthNow = '0' + monthNow;
}
if (dayNow < 10) {
  dayNow = '0' + dayNow;
}
nowDateStr = yearNow + '-' + monthNow + '-' + dayNow;

const formatDateTimeStrFun = (date) => {

  let yearCurrent = date.getFullYear();
  let monthCurrent = date.getMonth() + 1;
  let dayCurrent = date.getDate();
  if (monthCurrent < 10) {
    monthCurrent = '0' + monthCurrent;
  }
  if (dayCurrent < 10) {
    dayCurrent = '0' + dayCurrent;
  }
  currentDateStr = yearCurrent + '-' + monthCurrent + '-' + dayCurrent;

  let hourCurrent = date.getHours();
  let minuteCurrent = date.getMinutes();
  let secondsCurrent = '00';

  if (hourCurrent < 10) {
    hourCurrent = '0' + hourCurrent;
  }
  if (minuteCurrent < 10) {
    minuteCurrent = '0' + minuteCurrent;
  }

  currentTimeStr = hourCurrent + ':' + minuteCurrent + ':' + secondsCurrent;

}

formatDateTimeStrFun(now)

const formatDateStrFun = (date) => {
  if (!date) {
    date = now
  }
  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  const md = date.getFullYear() + '年' + month + '月' + day + '日';

  const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const weekIdx = date.getDay();
  let week = weeks[weekIdx];
  return md + ' ' + week;
}

const minDate = now; // 最小选择日期
const maxDate = new Date(minDate.getTime() + 33 * 24 * 60 * 60 * 1000); // 最大选择日期

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const alert = Modal.alert;

export default class TicketSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      date: now,
      dateStrShow: formatDateStrFun(now),
      titleList: [{xl: '乘客', op: '是否乘坐'}],
      yyList: [],
      loading: false,
      xlLabel: '',
      xlValue: [],
      xlVisible: false,
      xlList: [],
      xlShowList: [],
      selectedXlId: ''
    }
  }

  componentDidMount = () => {
    formatDateTimeStrFun(now)
    currentDate = now
    this.getXlAllList()
  }

  getXlAllList = () => {
    Axios({
      method: 'get',
      url: BASE_URL + "xlb/getAllList",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        this.setState({xlList: resData.data});
        if (resData.data && resData.data.length > 0) {
          let showList = [];
          for (const xl of resData.data) {
            let showEle = {
              label: '',
              value: ''
            }

            showEle.value = xl.id
            showEle.label = xl.cfd + ' - ' + xl.mdd + ' 【' + xl.fx + '】'

            showList.push(showEle)
          }

          if (showList.length > 0) {
            let xlValArr = []
            xlValArr.push(showList[0].value)
            this.setState({xlValue: xlValArr})
            this.setState({xlLabel: showList[0].label})
            this.setState({selectedXlId: showList[0].value})
          }
          this.state.xlShowList.push(showList)

          this.getOrderedPerson()
        }
      } else {
        Toast.fail("获取线路列表失败!");
      }
    });
  }

  getOrderedPerson = () => {
    let params = {
      xlId: this.state.selectedXlId,
      yysj: currentDateStr,
    }
    Axios({
      method: 'get',
      url: BASE_URL + "cpyy/getOrderedPersonByXlId",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        this.setState({yyList: resData.data});
      } else {
        Toast.fail("获取已预约列表失败!");
      }
    });
  }

  getDateStrShow = () => {
    return this.state.dateStrShow;
  }

  onConfirm = (date) => {
    currentDate = date;
    this.setState({date: date, visible: false})
    this.formatDateStr(date)
  }

  onXlConfirm = (v) => {
    if (v && v.length > 0) {
      this.state.selectedXlId = v[0]
      const selectedXlShow = this.state.xlShowList[0].filter(item => {
        return item.value === v[0]
      })
      this.setState({xlLabel: selectedXlShow[0].label})
      this.setState({xlValue: v})
    }
    this.setState({xlVisible: false})
    this.getOrderedPerson()
  }

  formatDateStr = (date) => {
    const dateStrShow = formatDateStrFun(date);
    formatDateTimeStrFun(date)
    this.setState({dateStrShow: dateStrShow});

    this.getOrderedPerson()
  }

  isCanModifyStatus = () => {
    return currentDateStr > nowDateStr;
  }

  modifyYyStatus = (item, type) => {
    this.setState({loading: true})
    let title = ''
    if (type === 'yes') {
      title = '乘客已上车乘坐'
    } else {
      title = '乘客未上车乘坐'
    }
    alert(title, '确定吗？', [
      {text: '取消', onPress: () => this.setState({loading: false})},
      {
        text: '确定', onPress: () => {
          Axios({
            method: 'post',
            url: BASE_URL + "cpyy/modifyBySfzhAndXlId",
            params: {
              sfzh: item.sfzh,
              xlId: item.xlid,
              yysj: currentDateStr,
              type: type,
            },
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          }).then((res) => {
            this.setState({loading: false})
            const resData = res.data;
            if (resData) {
              if (resData.flag) {
                this.getOrderedPerson()
                Toast.info(resData.msg);
              } else {
                Toast.fail(resData.msg);
              }
            } else {
              Toast.fail("确认错误!");
            }
          });
        }
      },
    ])
  }

  getListByYyidList = () => {
    let params = {
      yysj: currentDateStr
    }
    Axios({
      method: 'get',
      url: BASE_URL + "bccx/getListByYyidList",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        if (!resData.data || resData.data.length === 0) {
          Toast.info("导出数据为空！");
          return;
        }
        // 创建一个工作簿，并添加工作表
        const workbook = XLSX.utils.book_new();
        // 创建工作表并定义列标题
        const headerArray = [
          {name: '序号', width: 5},
          {name: '线路', width: 20},
          {name: '姓名', width: 20},
          {name: '身份证号', width: 22},
          {name: '一卡通号', width: 10},
          {name: '手机号', width: 14},
          {name: '班次', width: 20},
          {name: '班次类型', width: 8},
          {name: '出发时间', width: 20},
          {name: '到达时间', width: 20},
          {name: '有行李箱', width: 8}
        ];
        // 创建一个工作表
        const worksheet = XLSX.utils.aoa_to_sheet([
          [`${currentDateStr}乘客线路预约信息`],  //这里是要合并单元格的，所以写一个数据就行
          ["序号", "线路", "姓名", "身份证号", "一卡通号", "手机号", "班次", "班次类型", "出发时间", "到达时间", "有行李箱"], //第二列表头
        ]);
        //合并单元格 这里指定列合并单元格的范围,与列宽度设置类似也是一个数组
        let excelMerges = [];
        excelMerges.push({
          s: {r: 0, c: 0},
          e: {r: 0, c: headerArray.length - 1}, //合并范围是第1行第一列到第1行第len - 1列
        });
        worksheet["!merges"] = excelMerges; //可以打印worksheet数据来看，以！开头的就是设置列宽行高合并的，其他就是单元格

        // 遍历后端数据并写入工作表
        resData.data.forEach((row, rowIndex) => {
          const sheetRow = [];
          //循环列
          for (const h of headerArray) {
            sheetRow.push(row[h.name]); // 替换为实际属性名或处理空值
          }
          //将数据添加到工作表中，从第三行开始add，因为一二行是表头
          XLSX.utils.sheet_add_aoa(worksheet, [sheetRow], {origin: rowIndex + 2});
        });

        //开始添加样式
        const styles = {
          //第一行表头的样式
          firstHeader: {
            font: {bold: true, name: "微软雅黑", sz: 16},
            alignment: {
              //文字居中
              horizontal: "center",
              vertical: "center",
              wrap_text: true,
            },
            border: {
              top: {style: "thin"},
              left: {style: "thin"},
              right: {style: "thin"},
              bottom: {style: "thin"},
            },
          },
          //第二行表头的样式
          headerStyle: {
            font: {bold: true, name: "微软雅黑", sz: 11},
            fill: {fgColor: {rgb: "C0C0C0"}}, // 背景色
            alignment: {
              //文字居中
              horizontal: "center",
              vertical: "center",
              wrap_text: true,
            },
            border: {
              top: {style: "thin"},
              left: {style: "thin"},
              right: {style: "thin"},
              bottom: {style: "thin"},
            },
            // height: 40,
          },
          //其他单元格的样式
          cellStyle: {
            font: {name: "微软雅黑", sz: 11},
            alignment: {
              //文字居中
              horizontal: "center",
              vertical: "center",
              wrap_text: true,
            },
            border: {
              top: {style: "thin"},
              left: {style: "thin"},
              right: {style: "thin"},
              bottom: {style: "thin"},
            },
            // height: 40,
          },
        };

        // 设置列宽行高
        //先声明worksheet["!rows"]、worksheet["!cols"]
        if (!worksheet["!rows"] || !worksheet["!cols"]) {
          worksheet["!rows"] = [];
          worksheet["!cols"] = [];
        }
        //worksheet["!ref"]是工作表的范围
        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        //循环列，设置列宽为20字符，也可以设置像素wpx：200
        for (let i = 0; i < headerArray.length; i++) {
          worksheet["!cols"][i] = {wch: headerArray[i].width};
        }
        //循环行，设置第一行像素为40，其余行为30
        for (let i = range.s.r + 1; i < range.e.r + 2; i++) {
          worksheet["!rows"][0] = {hpx: 40};
          worksheet["!rows"][i] = {hpx: 30};
        }

        // 应用样式到列头
        for (let col of ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"]) {
          let cellRef1 = `${col}1`;
          if (worksheet[cellRef1]) {
            worksheet[cellRef1].s = styles.firstHeader;
          }
          let cellRef2 = `${col}2`;
          if (worksheet[cellRef2]) {
            worksheet[cellRef2].s = styles.headerStyle;
          }
        }
        //应用样式到单元格
        for (let row = 3; row < range.e.r + 2; row++) {
          for (let col of ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"]) {
            let cellRef = `${col}${row}`;
            if (worksheet[cellRef]) {
              worksheet[cellRef].s = styles.cellStyle;
            }
          }
        }
        // 添加工作表到工作簿
        XLSX.utils.book_append_sheet(workbook, worksheet, "乘客线路信息");
        // 确保导出时包含样式信息，一定要使用xlsxstyle来写入，不然就没有样式了
        const wbOut = XLSXStyle.write(workbook, {bookType: "xlsx", type: "binary"});

        saveAs(
          // Blob: 对象表示一个不可变 原始数据的类文件对象,不一定是JS原生格式的数据。
          // File: 基于Blob，继承了blob的功能并将其扩展使其支持用户系统上的文件。
          new Blob([this.s2ab(wbOut)], {type: "appliction/octet-stream"}),
          // 设置导出的文件名称可随意
          `${currentDateStr}乘客线路预约信息.xlsx`,
        );

        // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        // // 生成 Excel 文件并使用 FileSaver.js 保存文件
        // XLSX.writeFile(wb, '预约数据' + '.xlsx');
      } else {
        Toast.fail(resData.msg);
      }
    });
  }

  s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  exportYyData = () => {
    this.getListByYyidList();
  }

  myTitleList = () => (
    <List>
      {this.state.titleList.map(item => (
        <ListItem key={item.xl}>
          <div className="item-wrapper">
            <div className="col1">
              {item.xl}
            </div>
            <div className="col2">
              {item.op}
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )

  myList = () => (
    <List>
      {this.state.yyList.map(item => (
        <ListItem key={item.xlid + item.sfzh}>
          <div className="item-wrapper">
            <div className="yy-col1">
              <div style={{textAlign: 'left', fontSize: "16px", marginTop: '5px'}}>
                {item.yyrxm}
              </div>
              <div style={{fontSize: "12px", marginTop: '5px', color: '#666'}}>
                {item.sfzh}
              </div>
              <div style={{fontSize: "12px", marginTop: '5px', color: '#666'}}>
                {item.sjh}
              </div>
              <div style={{fontSize: "12px", marginTop: '5px', marginBottom: '5px', color: '#666'}}>
                {item.ykt}
              </div>
            </div>
            <div className="yy-col2">
              <Button type="primary" style={{display: item.status === '0' ? 'block' : 'none'}} size="small"
                      onClick={() => this.modifyYyStatus(item, 'yes')}
                      disabled={this.state.loading || this.isCanModifyStatus()}>&emsp;是&emsp;</Button>
              <Button type="warning" style={{display: item.status === '0' ? 'block' : 'none'}} size="small"
                      onClick={() => this.modifyYyStatus(item, 'no')}
                      disabled={this.state.loading || this.isCanModifyStatus()}>&emsp;否&emsp;</Button>
              <Tag selected={item.status === '1'} style={{display: item.status === '0' ? 'none' : 'block'}}>
                {item.status === '1' ? '已乘坐' : item.status === '2' ? '未乘坐' : ''}
              </Tag>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )

  render() {
    return (
      <div className="container">
        <NavBar
          mode="dark"
          icon={<Icon type="left"/>}
          onLeftClick={() => this.props.history.goBack()}
          rightContent={<div onClick={() => this.exportYyData()}>导出</div>}
        >车票查询</NavBar>
        <div className="ordered-wrapper">
          <div className="date-tool">
            <div className="date-label">
              日期选择：
            </div>
            <div className="date-show">
              <Button size="small" style={{color: '#1677ff'}} disabled={false} onClick={() => {
                this.setState({
                  visible: true,
                });
              }}>
                {this.getDateStrShow()}
              </Button>
            </div>
          </div>
          <div className="xl-tool">
            <div className="xl-label">
              线路选择：
            </div>
            <div className="xl-show">
              <Button size="small" style={{color: '#1677ff'}} disabled={false} onClick={() => {
                this.setState({
                  xlVisible: true,
                });
              }}>
                {this.state.xlLabel}
              </Button>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="table-title">
              <div className="cancel-tips"
                   style={{display: this.isCanModifyStatus() ? 'block' : 'none'}}>提示：未到乘车日期，不可确认是否乘坐！
              </div>
              <div className="confirm-tips"
                   style={{display: this.isCanModifyStatus() ? 'none' : 'block'}}>提示：请您确认乘客是否乘坐本线路。
              </div>
              {this.myTitleList()}
            </div>
            <div className="table-content">
              {this.myList()}
            </div>
          </div>

          <DatePicker
            visible={this.state.visible}
            mode="date"
            title="日期选择"
            value={this.state.date}
            maxDate={maxDate}
            onOk={date => this.onConfirm(date)}
            onDismiss={() => this.setState({visible: false})}
          />

          <Picker
            visible={this.state.xlVisible}
            data={this.state.xlShowList}
            title="选择路线"
            cols={1}
            value={this.state.xlValue}
            cascade={false}
            onOk={v => this.onXlConfirm(v)}
            onDismiss={() => this.setState({xlVisible: false})}
          />
        </div>
      </div>
    )
  }
}
