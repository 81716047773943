import './index.css'
import React, {Component} from 'react'
import {Badge, Button, DatePicker, Icon, InputItem, List, Modal, NavBar, Tabs, Toast} from 'antd-mobile';
import {Axios, BASE_URL} from '../../../utils/url'
import ListItem from "antd-mobile/es/list/ListItem";
import {getDateStr, getDateTimeStr} from '../../../utils/dateUtils'
import closest from "antd-mobile/es/_util/closest";

const now = new Date();

let preDayDisabled = true
let nextDayDisabled = false
let currentDate = new Date(now.getTime() + 24 * 60 * 60 * 1000);
let nowDateStr;
let currentDateStr = '';
let currentTimeStr = ''
let yearNow = now.getFullYear();
let monthNow = now.getMonth() + 1;
let dayNow = now.getDate();
if (monthNow < 10) {
  monthNow = '0' + monthNow;
}
if (dayNow < 10) {
  dayNow = '0' + dayNow;
}
nowDateStr = yearNow + '-' + monthNow + '-' + dayNow;

const formatDateTimeStrFun = (date) => {

  let yearCurrent = date.getFullYear();
  let monthCurrent = date.getMonth() + 1;
  let dayCurrent = date.getDate();
  if (monthCurrent < 10) {
    monthCurrent = '0' + monthCurrent;
  }
  if (dayCurrent < 10) {
    dayCurrent = '0' + dayCurrent;
  }
  currentDateStr = yearCurrent + '-' + monthCurrent + '-' + dayCurrent;

  let hourCurrent = date.getHours();
  let minuteCurrent = date.getMinutes();
  let secondsCurrent = '00';

  if (hourCurrent < 10) {
    hourCurrent = '0' + hourCurrent;
  }
  if (minuteCurrent < 10) {
    minuteCurrent = '0' + minuteCurrent;
  }

  currentTimeStr = hourCurrent + ':' + minuteCurrent + ':' + secondsCurrent;

}

formatDateTimeStrFun(currentDate)

const minDate = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 最小选择日期
const maxDate = new Date(minDate.getTime() + 2 * 24 * 60 * 60 * 1000); // 最大选择日期

let currentWeek = null;
const formatDateStrFun = (date) => {
  if (!date) {
    date = now
  }
  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  const md = month + '月' + day + '日';

  const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const weekIdx = date.getDay();
  let week = weeks[weekIdx];
  currentWeek = week;
  return md + ' ' + week;
}

const minDateStr = formatDateStrFun(minDate);
const maxDateStr = formatDateStrFun(maxDate);

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default class TicketBook extends Component {

  constructor(props) {
    super(props);
    const loc = this.props.location
    this.state = {
      ticketRoutes: [],
      date: currentDate,
      dateStr: getDateStr(currentDate),
      dpValue: null,
      visible: false,
      dateStrShow: formatDateStrFun(currentDate),
      modalCpyy: false,
      tipsVisible: loc.query && loc.query.openTips ? loc.query.openTips : false,
      loading: false,
    }
  }

  componentDidMount = () => {
    this.getXlAllList()
  }

  getXlAllList = () => {
    let params = {
      yysj: currentDateStr
    }
    Axios({
      method: 'get',
      url: BASE_URL + "xlb/getAllList",
      params: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        this.setState({ticketRoutes: resData.data});
      } else {
        Toast.fail("获取线路列表失败!");
      }
    });
  }

  myList = () => (
    <List>
      {this.state.ticketRoutes.map(item => (
        <ListItem key={item.id}>
          <div className="item-wrapper">
            <div className="item-left">
              <div style={{textAlign: "center", fontSize: "16px", margin: '5px 0'}}>
                <Badge text={item.fx} style={{
                  marginLeft: 12,
                  backgroundColor: item.fx === '回' ? '#21B68A' : '#ff5b05'
                }}>{item.cfd} - {item.mdd}</Badge>
              </div>
              <div
                style={{textAlign: "center", fontSize: "12px", marginTop: '5px', color: "red"}}>
                乘坐地点：{item.czdd}
              </div>
              <div style={{
                textAlign: "center",
                fontSize: "12px",
                marginTop: '5px',
                display: item.bczw ? 'block' : 'none'
              }}>
                余座：<span style={{color: '#21B68A'}}>{item.bczw}</span>
              </div>
            </div>
            <div className="item-right">
              <Button type="primary" size="small" onClick={() => this.preOrder(item)}
                      disabled={this.isCanPreOrder(item) || this.state.loading}>预约</Button>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  )

  preOrder = (item) => {
    this.getRemainSeats(item);
  }

  getRemainSeats = (item) => {
    this.setState({loading: true})
    Axios({
      method: 'post',
      url: BASE_URL + "cpyy/getRemainSeats",
      params: {
        xlid: item.id,
        yysj: currentDateStr,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      const resData = res.data;
      if (resData && resData.flag) {
        const seats = resData.data;
        if (seats && seats === 0) {
          Toast.info("余座为0，无法预约！")
          this.setState({loading: false})
        } else {
          this.isBreakOrderOver(item);
        }
      } else {
        Toast.fail("查询余座错误!");
      }
    });
  }

  isBreakOrderOver = (item) => {
    // 验证当前用户是否可以预约
    Axios({
      method: 'post',
      url: BASE_URL + "cpyy/isBreakOrderOver",
      params: {
        sfzh: userInfo.sfzh,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then((res) => {
      this.setState({loading: false})
      const resData = res.data;
      if (resData && resData.flag) {
        let isBreakOrder = resData.data;
        if (isBreakOrder) {
          Toast.info("您今年已违约超3次，不可再次预约！")
        } else {
          this.props.history.push({
            pathname: "./add-person",
            search: '?xlId=' + item.id + '&yysj=' + this.state.dateStr
          });
        }
      } else {
        Toast.fail("查询违约次数错误!");
      }
    });
  }

  isCanPreOrder = (item) => {
    if (item.mdd === '喀什酒店' && currentWeek && !"星期三,星期五,星期日".includes(currentWeek)) {
      return true;
    }
    const currentFcsj = currentDateStr + " " + "19:30:00";
    const nowDateTimeStr = nowDateStr + " " + currentTimeStr;

    const fcsjDate = new Date(currentFcsj)
    fcsjDate.setDate(fcsjDate.getDate() - 1); // 减去一天，当天19:30之后不可预约
    const newFcsj = getDateTimeStr(fcsjDate);

    return newFcsj <= nowDateTimeStr;
  }

  formatDateStr = (date) => {
    const dateStrShow = formatDateStrFun(date);
    formatDateTimeStrFun(date)
    this.setState({dateStrShow: dateStrShow});
  }

  getDateStrShow = () => {
    return this.state.dateStrShow;
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
  }

  //判断是否是微信浏览器的函数
  isWeiXin() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  }

  onConfirm = (date) => {
    currentDate = date;
    this.setState({date: date, visible: false})
    this.setState({dateStr: getDateStr(currentDate)});
    this.formatDateStr(date)

    const currentDateStr = formatDateStrFun(date)

    preDayDisabled = minDateStr === currentDateStr;
    nextDayDisabled = maxDateStr === currentDateStr;
  }

  clickChangeDay = (type) => {
    // 创建一个新的 Date 对象，避免直接修改原日期对象
    currentDate = new Date(this.state.date.getTime());
    // 获取当前日期的天数并加一
    let day = currentDate.getDate();
    if (type === 'nextDay') {
      currentDate.setDate(day + 1);
    } else if (type === 'preDay') {
      currentDate.setDate(day - 1);
    }

    this.setState({date: currentDate});
    this.setState({dateStr: getDateStr(currentDate)});

    this.formatDateStr(currentDate)

    const currentDateStr = formatDateStrFun(currentDate)
    formatDateTimeStrFun(currentDate)

    preDayDisabled = minDateStr === currentDateStr;
    nextDayDisabled = maxDateStr === currentDateStr;

    this.getXlAllList()
  }

  toHistoryOrder = () => {
    this.props.history.push({pathname: "./history-order"});
  }

  onWrapTouchStart = (e) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, '.am-modal-content');
    if (!pNode) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className="container">
        <NavBar
          mode="dark"
          icon={<Icon type="left"/>}
          onLeftClick={() => this.props.history.goBack()}
          rightContent={<div onClick={() => this.toHistoryOrder()}>预约历史</div>}
        >车票预约</NavBar>
        <div className="cpyy-wrpper">
          <div className="order-tips">
            <p style={{margin: '5px 0'}}>1.预约请至少提前一天，时间截至到前一天的19:30</p>
            <p style={{margin: '5px 0'}}>2.因火车、航班变动需取消原预约后重新预约</p>
            <p style={{margin: '5px 0'}}>3.特殊情况请致电7523369</p>
            <p style={{margin: '5px 0'}}>4.一年内违约3次及以上将不可再预约</p>
            <p style={{margin: '5px 0'}}>5.石油基地出发的班次请提前2小时乘车</p>
            <p style={{margin: '5px 0'}}>6.开往喀什酒店的班车为每周三、五、日早上9点整</p>
          </div>
          <Tabs tabs={[{title: "线路列表"}]}>
            <div className="ticket-content">
              <div className="date-wrapper">
                <div className="pre-day">
                  <Button size="small" style={{color: '#1677ff'}} disabled={preDayDisabled}
                          onClick={() => this.clickChangeDay('preDay')}>
                    前一天
                  </Button>
                </div>
                <div className="date-chose">
                  <Button size="small" style={{color: '#1677ff'}} disabled={false} onClick={() => {
                    this.setState({
                      visible: true,
                    });
                  }}>
                    {this.getDateStrShow()}
                  </Button>
                </div>
                <div className="next-day">
                  <Button size="small" style={{color: '#1677ff'}} disabled={nextDayDisabled}
                          onClick={() => this.clickChangeDay('nextDay')}>
                    后一天
                  </Button>
                </div>
              </div>
              <div className="ticket-list">
                {this.myList()}
              </div>
            </div>
          </Tabs>
          <DatePicker
            visible={this.state.visible}
            mode="date"
            title="日期选择"
            value={this.state.date}
            minDate={minDate}
            maxDate={maxDate}
            onOk={date => this.onConfirm(date)}
            onDismiss={() => this.setState({visible: false})}
          />
        </div>
        <Modal
          visible={this.state.tipsVisible}
          transparent
          maskClosable={false}
          style={{width: '95%', height: '350px'}}
          onClose={this.onClose('tipsVisible')}
          wrapProps={{onTouchStart: this.onWrapTouchStart}}
        >
          <div className="tips-modal-wrapper">
            <div className="tips-modal-title">
              预约提示
            </div>
            <div className="tips-modal-content">
              <p style={{margin: '5px 0'}}>1.预约请至少提前一天，时间截至到前一天的19:30</p>
              <p style={{margin: '5px 0'}}>2.因火车、航班变动需取消原预约后重新预约</p>
              <p style={{margin: '5px 0'}}>3.特殊情况请致电7523369</p>
              <p style={{margin: '5px 0'}}>4.一年内违约3次及以上将不可再预约</p>
              <p style={{margin: '5px 0'}}>5.石油基地出发的班次请提前2小时乘车</p>
              <p style={{margin: '5px 0'}}>6.开往喀什酒店的班车为每周三、五、日早上9点整</p>
            </div>
            <div className="tips-modal-footer">
              <Button size="small" type="ghost" onClick={this.onClose('tipsVisible')}>已阅读</Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
